import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import "./PayerDetails.scss";
import TimeRemaining from "../../package/timeRemaining/TimeRemaining";

import pagsmileLogoM from "../../images/pagsmile_logo.png";  
import TimeIcon from "../../images/time_small.png";
import { getSessionStorage, merchantImgMap } from "../../utils/config";
const classNames = require("classnames");

function PayerDetails(props) {
  // console.log("PayerDetails-props", props);
  const { t } = useTranslation();
  const propsOrderDetail = props.tradeInfo.order_detail;
  let propsTradeFormDetail = null;
  if (
    JSON.stringify(props.tradeFormDetail) !== "{}" ||
    !props.tradeFormDetail
  ) {
    propsTradeFormDetail = props.tradeFormDetail;
  } else {
    propsTradeFormDetail =
      getSessionStorage("cacheFilledData")?.tradeFormDetail;
  }
  const [isDiabled, setIsDisabled] = useState(false);
  const disablePay = () => {
    setIsDisabled(true);
  };
  return (
    <div className="trade-details-box">
      <TimeRemaining
        image={TimeIcon}
        time={propsOrderDetail.remain_time}
        disable={disablePay}
      />
      <div className="trade-details-item">
        <p className={classNames("trade-title", "margin-top10")}>
          {t("payer")}
        </p>
        <div
          className="trade-item-box"
          style={{ marginTop: "-1.5rem", marginBottom: "0.5rem" }}
        >
          <p className="trade-label">{propsOrderDetail?.custom_name}</p>
          <p className="trade-value">
            {
              merchantImgMap[propsOrderDetail.id] ? (
                <img src={merchantImgMap[propsOrderDetail.id]} className="payer-img" alt="" />
              ) : (
                <img src={pagsmileLogoM} width="90" alt="" />
              )
            }
          </p>
        </div>
      </div>
      <div className="trade-details-item">
        <p className="trade-title">{t("general")}</p>
        <div className="trade-item-box">
          <p className="trade-label">{t("transaction_id")}</p>
          <p className="trade-value">{propsOrderDetail?.custom_code}</p>
        </div>
        <div className="trade-item-box">
          <p className="trade-label">{t("description")}</p>
          <p className="trade-value">{propsOrderDetail?.description}</p>
        </div>
      </div>
      <div className={classNames("trade-details-item", "border-bottom-none")}>
        <p className="trade-title">{t("currency")}</p>
        <div className="trade-item-box">
          <p className="trade-label">{t("from")}</p>
          <p className="trade-value">
            {propsOrderDetail?.payout_currency}
            {propsOrderDetail?.payout_currency === "BRL" ? (
              <span className="trade-tip">
                {(propsOrderDetail?.payout_amount)
                  .toString()
                  ?.replace(".", ",")}
              </span>
            ) : (
              <span className="trade-tip">
                {propsOrderDetail?.payout_amount}
              </span>
            )}
          </p>
        </div>
        <div className="trade-item-box">
          <p className="trade-label">{t("exchange_rate")}</p>
          <p className="trade-value">
            {propsTradeFormDetail?.exchange_rate || "--"}
          </p>
        </div>
        <div className="trade-item-box">
          <p className="trade-label">{t("to")}</p>
          <p className="trade-value">
            {propsTradeFormDetail?.arrival_currency}
            {propsTradeFormDetail?.arrival_currency === "BRL" ? (
              <span className="trade-tip">
                {(propsTradeFormDetail?.arrival_amount)
                  .toString()
                  .replace(".", ",") || "--"}
              </span>
            ) : (
              <span className="trade-tip">
                {propsTradeFormDetail?.arrival_amount || "--"}
              </span>
            )}
          </p>
        </div>
        <div className="trade-item-box">
          <p className="trade-label">{t("taxes")}</p>
          <p className="trade-value">
            {propsTradeFormDetail?.tax * 1 === 0
              ? ""
              : propsTradeFormDetail?.arrival_currency}
            {propsTradeFormDetail?.arrival_currency === "BRL" ? (
              <span className="trade-tip">
                {propsTradeFormDetail?.tax * 1 === 0
                  ? ""
                  : (propsTradeFormDetail?.tax).toString().replace(".", ",") ||
                    "--"}
              </span>
            ) : (
              <span className="trade-tip">
                {propsTradeFormDetail?.tax || "--"}
              </span>
            )}
          </p>
        </div>
        <div className="trade-item-box">
          <p className="trade-label">{t("bank_fee")}</p>
          <p className="trade-value">
            {propsTradeFormDetail?.fee * 1 === 0 ? "" : propsTradeFormDetail?.arrival_currency}
            {propsTradeFormDetail?.arrival_currency === "BRL" ? (
              <span className="trade-tip">
                {propsTradeFormDetail?.fee * 1 === 0
                  ? ""
                  : (propsTradeFormDetail?.fee).toString().replace(".", ",") ||
                    "--"}
              </span>
            ) : (
              <span className="trade-tip">
                {propsTradeFormDetail?.fee || "--"}
              </span>
            )}
          </p>
        </div>
        <div className="trade-item-box" style={{ marginBottom: 0 }}>
          <p className="trade-title">{t("total")}</p>
          <p className="trade-value">
            {propsTradeFormDetail?.arrival_currency}
            {propsTradeFormDetail?.arrival_currency === "BRL" ? (
              <span className="trade-tip">
                {(propsTradeFormDetail?.total).toString().replace(".", ",") ||
                  "--"}
              </span>
            ) : (
              <span className="trade-tip">
                {propsTradeFormDetail?.total || "--"}
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
    tradeFormDetail: state.tradeFormDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(PayerDetails);
