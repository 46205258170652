import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import "./Footer.scss";

import SortDown from "../../images/sort_down.png";
import SortUp from "../../images/sort_up.png";
import TimeIcon from "../../images/time_small.png";

import TimeRemaining from "../../package/timeRemaining/TimeRemaining";
import { getSessionStorage } from "../../utils/config";
import { setShowToggle } from "../../redux/actions";
const classNames = require("classnames");

function Footer(props) {
  // console.log("footer-props", props);
  const { t } = useTranslation();
  const orderDetail = props.tradeInfo.order_detail;
  let tradeFormDetail = null;
  if (
    JSON.stringify(props.tradeFormDetail) !== "{}" ||
    !props.tradeFormDetail
  ) {
    tradeFormDetail = props.tradeFormDetail;
  } else {
    tradeFormDetail = getSessionStorage("cacheFilledData")?.tradeFormDetail;
  }
  const [footerDropDownState, setFooterDropDownState] = useState(false);
  const [isDiabled, setIsDisabled] = useState(true);
  useEffect(() => {
    setFooterDropDownState(props?.timeFlag);
    const remain = props.tradeInfo.order_detail.remain_time * 1000 - Date.now();
    if (remain > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
    return () => {
      props.setShowToggle(false)
    }
  }, []);
  const disablePay = () => {
    setIsDisabled(true);
  };

  const handleToggleBtn = () => {
    setFooterDropDownState(!footerDropDownState)
    props.setShowToggle(!footerDropDownState)
  }

  const handleClickIt = () => {
    //console.log("footer",props.cb)
    if (props.cb) {
      props.cb();
    }
  };

  return (
    <>
      <div
        className={classNames("footer-wrap", {
          show: isDiabled,
        })}
      >
        <img
          className="toggle-btn"
          src={footerDropDownState ? SortDown : SortUp}
          style={{ visibility: !props?.timeFlag ? "visible" : "hidden" }}
          alt=""
          onClick={handleToggleBtn}
        />
        <div
          className="footer-content"
          style={{ display: footerDropDownState ? "block" : "none" }}
        >
          <TimeRemaining
            image={TimeIcon}
            time={orderDetail.remain_time}
            disable={disablePay}
          />
          {props.currencyFlag === true && (
            <>
              <div className="con-item-box box1">
                <label className="item-name">{t("from")}</label>
                <span className="item-value">
                  <span className="tip">{orderDetail.payout_currency}</span>
                  {orderDetail.payout_amount}
                </span>
              </div>
              <div className="con-item-box box1">
                <label className="item-name">{t("exchange_rate")}</label>
                <span className="item-value">
                  {tradeFormDetail?.exchange_rate || "--"}
                </span>
              </div>
              <div className="con-item-box box1">
                <label className="item-name">{t("to")}</label>
                <span className="item-value">
                  <span className="tip">
                    {tradeFormDetail?.arrival_currency || "--"}
                  </span>
                  {tradeFormDetail?.arrival_amount}
                </span>
              </div>
              <div className="con-item-box box1">
                <label className="item-name">{t("taxes")}</label>
                <span className="item-value">
                  {tradeFormDetail?.tax || "--"}
                </span>
              </div>
              <div className="con-item-box box1">
                <label className="item-name">{t("bank_fee")}</label>
                <span className="item-value">
                  {tradeFormDetail?.fee || "--"}
                </span>
              </div>
            </>
          )}
        </div>
        {props.currencyFlag === true && (
          <div className="con-item-box box2">
            <label className="item-name">{t("total")}</label>
            <span className="item-value">
              <span className="currency">
                {tradeFormDetail?.arrival_currency}
              </span>
              {tradeFormDetail?.total || "--"}{" "}
            </span>
          </div>
        )}
      </div>
      <div className="confirm-box">
        {props.cb ? (
          <Button
            variant="contained"
            color="primary"
            className={classNames({
              disabled: isDiabled,
            })}
            onClick={handleClickIt}
          >
            {t("confirm")}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classNames({
              disabled: isDiabled,
            })}
          >
            {t("confirm")}
          </Button>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
    tradeFormDetail: state.tradeFormDetail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setShowToggle: (item) => dispatch(setShowToggle(item))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
