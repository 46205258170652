import React, { forwardRef, useState } from "react";
import "./DropDownMenu.scss";
import { connect } from "react-redux";
import Chat from "../../images/客服.png";
import ChatM from "../../images/客服black.png";
import FaqImg from "../../images/faq.png";
import FaqImgM from "../../images/faq_black.png";
import Back from "../../images/back.png";
import Instructions from "../../images/图文详情.png";
import InstructionsM from "../../images/图文详情black.png";
import Faq from "../faq/Faq";
import VideoContent from "../videoContent/VideoContent";
import { useTranslation } from "react-i18next";
import { methodConfig } from "../../utils/configurations";
import { setSessionStorage } from "../../utils/config"

function DropDownMenu(props, ref) {
  const [pageState, setPageState] = useState(props.state);
  const { t, i18n } = useTranslation();
  const handleLangChange = (event) => {
    i18n.changeLanguage(event.target.id);
    setSessionStorage("lng",event.target.id)
  };
  return (
    <div ref={ref} className="dropDown">
      <div className="dropDown__back">
        <img src={Back} alt="" onClick={props.back} />
      </div>
      {pageState === "main" && (
        <>
          <div className="dropDown__main">
            <div
              className="dropDown--section"
              onClick={() => {
                window?.LC_API?.open_chat_window();
              }}
            >
              <div className="dropDown--header">
                <img src={Chat} alt="" />
                <p>{t("help_chat")}</p>
              </div>
              {props.tradeInfo?.channel_info_list?.map((item) => {
                return <p>{methodConfig()[item.method].info.text}</p>;
              })}
            </div>
            <div
              className="dropDown--section"
              onClick={() => {
                setPageState("inst");
              }}
            >
              <div className="dropDown--header">
                <img src={Instructions} alt="" />
                <p>{t("instructions")}</p>
              </div>
              <p>{t("videos")}</p>
              <p>{t("pictures")}</p>
              <p>{t("text")}</p>
            </div>
            <div
              className="dropDown--section"
              onClick={() => {
                setPageState("faq");
              }}
            >
              <div className="dropDown--header">
                <img src={FaqImg} alt="" />
                <p>{t("faq")}</p>
              </div>
              {props.tradeInfo?.channel_info_list?.map((item) => {
                return methodConfig()[item.method]?.faq?.map((item) => (
                  <p>{item.q}</p>
                ));
              })}
            </div>
          </div>
          <div className="dropDown__mainMobile">
            <div className="dropDown__mainMobile__content">
              <div
                className="dropDown__mainMobile__title"
                onClick={() => {
                  window?.LC_API?.open_chat_window();
                }}
              >
                <img src={ChatM} alt="" />
                <p>{t("help_chat")}</p>
              </div>
              <div
                className="dropDown__mainMobile__title"
                onClick={() => {
                  setPageState("inst");
                }}
              >
                <img src={InstructionsM} alt="" />
                <p>{t("instructions")}</p>
              </div>
              <div
                className="dropDown__mainMobile__title"
                onClick={() => {
                  setPageState("faq");
                }}
              >
                <img src={FaqImgM} alt="" />
                <p>{t("faq")}</p>
              </div>
            </div>
          </div>
        </>
      )}
      {pageState === "lang" && (
        <div className="dropDown__lang">
          <p
            className="dropDown__lang__title"
            id="en"
            onClick={(e) => {
              handleLangChange(e);
              props.back();
            }}
          >
            English
          </p>
          <p
            className="dropDown__lang__title"
            id="es"
            onClick={(e) => {
              handleLangChange(e);
              props.back();
            }}
          >
            Español
          </p>
          <p
            className="dropDown__lang__title"
            id="pt"
            onClick={(e) => {
              handleLangChange(e);
              props.back();
            }}
          >
            Português
          </p>
        </div>
      )}

      {pageState === "faq" && <Faq />}
      {pageState === "inst" && (
        <div className="dropDown__ins">
          {props.tradeInfo?.channel_info_list?.map((item) => {
            return methodConfig()[item.method]?.video?.map((item) => (
              <VideoContent title={item.title} source={item.source} />
            ));
          })}
         
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(forwardRef(DropDownMenu));
