import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getSessionStorage } from "../../utils/config";

import "./ReceiverDetails.scss";
const classNames = require("classnames");

function ReceiverDetails(props) {
  //console.log("ReceiverDetails-props", props);
  const propsCountry = props.country
  const propsMethod = props.method;
  const { t } = useTranslation();
  const propsInfomation =
    props.tradeForm?.infomation ||
    getSessionStorage("cacheFilledData")?.infomation;

  return (
    <div className="trade-receiver-box">
      <div className="trade-details-item">
        <p className={classNames("trade-title", "margin-top10")}>
          {t("receiver_title1")}
        </p>
        <div className="trade-item-box">
          <p className="trade-label">{props.country}</p>
        </div>
      </div>
      <div className="trade-details-item">
        <p className={classNames("trade-title", "margin-top10")}>
          {t("receiver_title2")}
        </p>
        <div className="trade-item-box">
          <p className="trade-label">{props.method === 'WALLET' ? 'PAYPAL': props.method}</p>
        </div>
      </div>
      {(propsCountry === "MEX" && propsMethod === "SPEI") && (
        <div className={classNames("trade-details-item", "border-bottom-none")}>
          <p className="trade-title">{t("receiver_title3")}</p>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_name")}</p>
            <p className="trade-value">{propsInfomation.speiName}</p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_document_type")}</p>
            <p className="trade-value">{propsInfomation.speiDocumentType}</p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_document_id")}</p>
            <p className="trade-value">{propsInfomation.speiDocumentId}</p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_document_bank")}</p>
            <p className="trade-value">{propsInfomation.speiBank}</p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_account_type")}</p>
            <p className="trade-value">{propsInfomation.speiAccountType?.split(",")[1]}</p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_account")}</p>
            <p className="trade-value">{propsInfomation.speiAccount}</p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_email")}</p>
            <p className="trade-value">{propsInfomation.speiEmail}</p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_cellphone")}</p>
            <p className="trade-value">{propsInfomation.speiCellphone}</p>
          </div>
        </div>
      )}
      {(propsCountry === "BRA" && propsMethod === "PIX") && (
        <div className={classNames("trade-details-item", "border-bottom-none")}>
          <p className="trade-title">{t("receiver_title3")}</p>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_name")}</p>
            <p className="trade-value">{propsInfomation.pixName}</p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_name")}</p>
            <p className="trade-value">{propsInfomation.pixCpf_cnpj}</p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_key_type")}</p>
            <p className="trade-value">{propsInfomation.pixKeyType?.split(",")[1]}</p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_pix_key")}</p>
            <p className="trade-value">{propsInfomation.pixKey}</p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_email")}</p>
            <p className="trade-value">{propsInfomation.pixEmail}</p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_cellphone")}</p>
            <p className="trade-value">{propsInfomation.pixCellphone}</p>
          </div>
        </div>
      )}

      {(propsCountry === "BRA" || propsCountry === "MEX" ) && propsMethod === "WALLET" && (
        <div className={classNames("trade-details-item", "border-bottom-none")}>
          <p className="trade-title">{t("receiver_title3")}</p>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_name")}</p>
            <p className="trade-value">{propsInfomation.walletName}</p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_cpf_cnpj")}</p>
            <p className="trade-value">{propsInfomation.walletCpf_cnpj}</p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_account")}</p>
            <p className="trade-value">{propsInfomation.walletAccount}</p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_email")}</p>
            <p className="trade-value">{propsInfomation.walletEmail}</p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_cellphone")}</p>
            <p className="trade-value">{propsInfomation.walletCellphone}</p>
          </div>
        </div>
      )}

      {(propsCountry === "GLOBAL" && propsMethod === "WALLET") && (
        <div className={classNames("trade-details-item", "border-bottom-none")}>
          <p className="trade-title">{t("receiver_title3")}</p>
          <div className="trade-item-box">
            <p className="trade-label">{t("paypal_country")}</p>
            <p className="trade-value">{propsInfomation.paypalCountry}</p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("paypal_account")}</p>
            <p className="trade-value">{propsInfomation.paypalAccount}</p>
          </div>
        </div>
      )}

      {(propsCountry === "BRA" && propsMethod === "BANKTRANSFER") && (
        <div className={classNames("trade-details-item", "border-bottom-none")}>
          <p className="trade-title">{t("receiver_title3")}</p>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_name")}</p>
            <p className="trade-value">{propsInfomation.banktranferName}</p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_cpf_cnpj")}</p>
            <p className="trade-value">{propsInfomation.banktranferCpf_cnpj}</p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_bank")}</p>
            <p className="trade-value">{propsInfomation.banktranferBank}</p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_account_type")}</p>
            <p className="trade-value">
              {propsInfomation.banktranferAccountType?.split(",")[1]}
            </p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_account")}</p>
            <p className="trade-value">
              {propsInfomation.banktranferAccountNumber}
            </p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_account_digit")}</p>
            <p className="trade-value">
              {propsInfomation.banktranferAccountDigit}
            </p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_bank_branch")}</p>
            <p className="trade-value">
              {propsInfomation.banktranferBankBranch}
            </p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_branch_digit")}</p>
            <p className="trade-value">
              {propsInfomation.banktranferBranchDigit}
            </p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_email")}</p>
            <p className="trade-value">{propsInfomation.banktranferEmail}</p>
          </div>
          <div className="trade-item-box">
            <p className="trade-label">{t("receiver_cellphone")}</p>
            <p className="trade-value">
              {propsInfomation.banktranferCellphone}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeForm: state.tradeForm,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(ReceiverDetails);
