import {
  SET_TRADE_INFO,
  SET_TRADE_FORM,
  SET_TRADE_FORM_DETAIL,
  SET_SHOW_POLICY,
  SET_SHOW_TOGGLE
} from "./types";

const initialState = {
  tradeInfo: "",
  tradeForm: {},
  tradeFormDetail: {},
  showPolicy: false,
  showToggle: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRADE_INFO:
      return {
        ...state,
        tradeInfo: action.item,
      };
    case SET_TRADE_FORM:
      return {
        ...state,
        tradeForm: action.item,
      };
    case SET_TRADE_FORM_DETAIL:
      return {
        ...state,
        tradeFormDetail: action.item,
      };
    case SET_SHOW_POLICY:
      return {
        ...state,
        showPolicy: action.item,
      };
    case SET_SHOW_TOGGLE:
      return {
        ...state,
        showToggle: action.item,
      };
    default:
      return state;
  }
};

export default reducer;
