import React, { Component } from "react";
import Header from "../components/header/Header";
import notfindImg from "../images/404.png";

export default class _404Page extends Component {
  render() {
    return (
      <div>
        <Header back={true} />
        <img
          src={notfindImg}
          alt=""
          style={{
            width: "25rem",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto",
          }}
        />
      </div>
    );
  }
}
