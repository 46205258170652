import { cpf, cnpj } from "cpf-cnpj-validator";
import { toast } from "react-toastify";

import Spei from "../images/spei.png";
import Pix from "../images/pix.png";
import Banktranfer from "../images/banktranfer.png";
import Wallet from "../images/wallet.png";
import Zuoshaowei from "../images/merchant/weige.jpg";

const _format = (val) => {
  const len = val.replace(/[^0-9]/gi, "").length;
  if (len !== 14) {
    // cpf
    if (cpf.isValid(val)) {
      return cpf.format(val);
    } else {
      return val.replace(/[^0-9]/gi, "");
    }
  } else {
    // cnpj
    if (cnpj.isValid(val)) {
      return cnpj.format(val);
    } else {
      return val.replace(/[^0-9]/gi, "");
    }
  }
};

const _formatCpf = (val) => {
  if (cpf.isValid(val)) {
    return cpf.format(val);
  } else {
    return val.replace(/[^0-9]/gi, "");
  }
};

const _formatCnpj = (val) => {
  if (cnpj.isValid(val)) {
    return cnpj.format(val);
  } else {
    return val.replace(/[^0-9]/gi, "");
  }
};

const methodConfig = () => {
  return {
    SPEI: Spei,
    PIX: Pix,
    BANKTRANSFER: Banktranfer,
    WALLET: Wallet,
  };
};

const getQueryVariable = (variable) => {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
};

const notify = (type = "error", text, duration=3000, cb) => {
  const _o = {
    position: "top-center",
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    pauseOnFocusLoss: false,
    draggable: true,
    progress: undefined,
  };
  if (type === "error") {
    toast.error(text, _o);
  } else if (type === "success") {
    toast.success(text, _o);
  }
  if(cb){
    setTimeout(() => {
      cb()
    },duration)
  }
  
};

const setSessionStorage = (key, val) => {
  if (typeof val === "object") {
    sessionStorage.setItem(key, JSON.stringify(val));
  } else {
    sessionStorage.setItem(key, val);
  }
};

const getSessionStorage = (key) => {
  if (
    sessionStorage.getItem(key)?.indexOf("{") >= 0 ||
    sessionStorage.getItem(key)?.indexOf("}") >= 0 || 
    sessionStorage.getItem(key)?.indexOf("[") >= 0 ||
    sessionStorage.getItem(key)?.indexOf("]") >= 0
  ) {
    return JSON.parse(sessionStorage.getItem(key));
  } else {
    return sessionStorage.getItem(key);
  }
};

const titleCase = (str) => {
  return str.toLowerCase().replace(/\b([\w|']+)\b/g, function(word) { 
    return word.replace(word.charAt(0), word.charAt(0).toUpperCase());  
});  
}

const removeSessionStorage = (key) => {
  sessionStorage.removeItem(key)
}

const clearSessionStorage = () => {
  sessionStorage.clear()
}

const phoneAreaCodeMap = {
  "BRA": {
    "areaCode": "55",
    "reg": /^(\+55|55)?\d{11}$/
  },
  "MEX": {
    "areaCode": "52",
    "reg": /^(\+52|52)?\d{6,10}$/
  }
}

const getPhoneAreaCode = (country,phoneVal) => {
  if(phoneVal.length === 11){
    return "+"+phoneAreaCodeMap[country]["areaCode"]+phoneVal
  }else if(phoneVal.length === 13){
    return "+"+phoneVal
  }else if(phoneVal.length === 14){
    return phoneVal
  }
}

const formatBank = (bankName,bankList) => {
  let bankCode = ""
  bankList.some(item => {
    if(item.name === bankName){
      bankCode = item.value
      return true;
    }
  })
  return bankCode;
}

const formatCountry = (countryName,countryList) => {
  let countryCode = ""
  countryList.some(item => {
    if(item.name === countryName){
      countryCode = item.value
      return true;
    }
  })
  return countryCode;
}

const merchantImgMap = {
  "32": Zuoshaowei
}

export {
  methodConfig,
  _format,
  _formatCpf,
  _formatCnpj,
  getQueryVariable,
  notify,
  setSessionStorage,
  getSessionStorage,
  removeSessionStorage,
  clearSessionStorage,
  titleCase,
  getPhoneAreaCode,
  phoneAreaCodeMap,
  merchantImgMap,
  formatBank,
  formatCountry
};
