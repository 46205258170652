import React from "react";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import "./Form.scss";

function FormSelect({ ...props }) {
  const handleChange = (e, item) => {
    if(!item){
      props.onChange(props.name, "");
    }else{
      if(props.name === "banktranferBank" || props.name === "speiBank" || props.name === "paypalCountry"){
        props.onChange(props.name, item.name);
      }else{
        props.onChange(props.name, item.value);
      }
      
    }
  };
  const handleBlur = () => {
    props.onBlur(props.name, true)
  }
  return (
    <div className="form-select">
      <Autocomplete
        options={props.options}
        value={{name: props.value}}
        onChange={handleChange}
        onBlur={handleBlur}
        getOptionLabel={(option) => option ? option.name : ""}
        renderInput={(params) => <TextField {...params} label={props.label} variant="outlined" />}
      />
      {props.touched && !props.value && props.error ? (
        <div className="form-error">{props.error}</div>
      ) : null}
    </div>
  );
}

export default FormSelect;
