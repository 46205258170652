import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

import { setTradeForm, setTradeFormDetail } from "../../redux/actions";
import FormSelect from "../../package/form/FormSelect";
import { Formik, Form } from "formik";
import {
  getQueryVariable,
  setSessionStorage,
  getSessionStorage,
} from "../../utils/config";

import "./CountryPage.scss";

function CountryPage(props) {
  //console.log("CountryPage-props",props)
  const { t } = useTranslation();
  const token = getQueryVariable("t");
  const history = useHistory();
  const countryOptions = props.tradeInfo.country_list;
  const countryLabelName = t("form.chooseCountry");

  const countryInitialValues = {
    country:
      props.tradeForm.country ||
      getSessionStorage("cacheFilledData")?.country ||
      "",
  };
  const countryValidationSchema = () =>
    Yup.lazy((values) => {
      return Yup.object({
        country: (() => {
          const r = t("form.chooseCountry");
          return Yup.string().required(r);
        })(),
      });
    });
  return (
    <div className="country-wrap">
      <Header back={true} />
      <div className="country-box">
        <Formik
          initialValues={countryInitialValues}
          validationSchema={countryValidationSchema()}
          onSubmit={async (values, { setSubmitting }) => {
            //console.log("values", values);
            if (values.country !== props.tradeForm.country) {
              props.setTradeForm({
                ...props.tradeForm,
                country: values.country,
                method: "",
              });
              props.setTradeFormDetail({});
            } else {
              props.setTradeForm({
                ...props.tradeForm,
                country: values.country,
              });
            }
            setSessionStorage("_od", props.tradeInfo.order_detail);
            setSessionStorage("cacheFilledData", { country: values.country });
            history.push("/?t=" + token);
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit} autoComplete="off">
              <FormSelect
                label={countryLabelName}
                name="country"
                options={countryOptions}
                onChange={props.setFieldValue}
                onBlur={props.setFieldTouched}
                value={props.values.country}
                error={props.errors.country}
                touched={props.touched.country}
              />
              <button type="submit" className="submit-btn">
                submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <Footer timeFlag={true} />
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
    tradeForm: state.tradeForm,
    tradeFormDetail: state.tradeFormDetail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setTradeForm: (item) => dispatch(setTradeForm(item)),
    setTradeFormDetail: (item) => dispatch(setTradeFormDetail(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CountryPage);
