import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Header from "../../components/header/Header";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import PayerDetails from "../../components/payerDetails/PayerDetails";

import stepInImg from "../../images/step-in.png";
import FinishedImg from "../../images/finished.png";
import {
  getQueryVariable,
  setSessionStorage,
  getSessionStorage,
} from "../../utils/config";

import "./HomePage.scss";
const classNames = require("classnames");

function HomePage(props) {
  //console.log("HomePage-props", props);
  const { t } = useTranslation();
  const token = getQueryVariable("t");
  const history = useHistory();
  const homeBox = useRef(null);
  const [formBtnDiabled, setFormBtnDiabled] = useState(true);

  useEffect(() => {
    const remain = props.tradeInfo.order_detail.remain_time * 1000 - Date.now();
    if (remain > 0) {
      if (
        (props.tradeForm.country ||
          getSessionStorage("cacheFilledData")?.country) &&
        (props.tradeForm.method ||
          getSessionStorage("cacheFilledData")?.method) &&
        JSON.stringify(props.tradeForm) !== "{}" &&
        JSON.stringify(props.tradeForm.infomation) !== "{}"
      ) {
        setFormBtnDiabled(false);
      } else {
        setFormBtnDiabled(true);
      }
    } else {
      setFormBtnDiabled(true);
    }
    if (props.history.action === "PUSH") {
      homeBox.current.scrollTop = homeBox.current.scrollHeight;
    }
  }, []);

  const handleClickCountry = () => {
    history.push("/choose-country?t=" + token);
  };
  const handleClickMehtod = () => {
    if (
      props.tradeForm.country ||
      getSessionStorage("cacheFilledData")?.country
    ) {
      history.push("/choose-method?t=" + token);
    }
  };
  const handleClickInfomation = () => {
    if (
      props.tradeForm.method ||
      getSessionStorage("cacheFilledData")?.method
    ) {
      history.push("/fill-info?t=" + token);
    }
  };
  const handleConfirmForm = () => {
    if (
      (props.tradeForm.country ||
        getSessionStorage("cacheFilledData")?.country) &&
      (props.tradeForm.method ||
        getSessionStorage("cacheFilledData")?.method) &&
      JSON.stringify(props.tradeForm) !== "{}" &&
      JSON.stringify(props.tradeForm.infomation) !== "{}"
    ) {
      //setPayerDetailsFlag(true);
      setSessionStorage("_od", props.tradeInfo.order_detail);
      history.push("/confirm-info?t=" + token);
    }
  };
  return (
    <>
      <div className="home-wrap">
        <Header />

        <div className="home-box" ref={homeBox}>
          <PayerDetails />
          <div
            className={classNames("fill-information-box", "finished")}
            onClick={handleClickCountry}
          >
            <div style={{ display: "flex" }}>
              <p className={classNames("step-num", "finished")}>1</p>
              <p className="step-text">{t("home_step1")}</p>
            </div>
            {!!props.tradeForm.country ||
            getSessionStorage("cacheFilledData")?.country ? (
              <img src={FinishedImg} className="step-finnished" alt="" />
            ) : (
              <img src={stepInImg} className="step-in-img" alt="" />
            )}
          </div>
          <div
            className={classNames("fill-information-box", {
              finished:
                !!props.tradeForm.country ||
                getSessionStorage("cacheFilledData")?.country,
            })}
            onClick={handleClickMehtod}
          >
            <div style={{ display: "flex" }}>
              <p
                className={classNames("step-num", {
                  finished:
                    !!props.tradeForm.country ||
                    getSessionStorage("cacheFilledData")?.country,
                })}
              >
                2
              </p>
              <p className="step-text">{t("home_step2")}</p>
            </div>
            {!!props.tradeForm.method ||
            getSessionStorage("cacheFilledData")?.method ? (
              <img src={FinishedImg} className="step-finnished" alt="" />
            ) : (
              <img src={stepInImg} className="step-in-img" alt="" />
            )}
          </div>
          <div
            className={classNames("fill-information-box", {
              finished:
                !!props.tradeForm.method ||
                getSessionStorage("cacheFilledData")?.method,
            })}
            onClick={handleClickInfomation}
          >
            <div style={{ display: "flex" }}>
              <p
                className={classNames("step-num", {
                  finished:
                    !!props.tradeForm.method ||
                    getSessionStorage("cacheFilledData")?.method,
                })}
              >
                3
              </p>
              <p className="step-text">{t("home_step3_t1")}</p>
            </div>
            {JSON.stringify(props.tradeForm.infomation) === "{}" ||
            !props.tradeForm.infomation ? (
              <img src={stepInImg} className="step-in-img" alt="" />
            ) : (
              <img src={FinishedImg} className="step-finnished" alt="" />
            )}
          </div>
        </div>
        <div className="form-btn-box">
          <Button
            variant="contained"
            color="primary"
            className={classNames({
              disabled: formBtnDiabled,
            })}
            onClick={handleConfirmForm}
          >
            {t("confirm")}
          </Button>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
    tradeForm: state.tradeForm,
    tradeFormDetail: state.tradeFormDetail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
