import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { connect } from "react-redux";
import PayerDetails from "../../components/payerDetails/PayerDetails";
import ReceiverDetails from "../../components/receiverDetails/ReceiverDetails";
import { PuffLoader } from "react-spinners";

import {
  getQueryVariable,
  setSessionStorage,
  getSessionStorage,
  notify,
  getPhoneAreaCode,
  removeSessionStorage,
  formatBank,
  formatCountry
} from "../../utils/config";
import $axios from "../../utils/http";

import "./ConfirmInfoPage.scss";
const classNames = require("classnames");

function ConfirmInfoPage(props) {
  //console.log("ConfirmInfoPage-props", props);
  const propsTradeInfo = props.tradeInfo;
  const token = getQueryVariable("t");
  const history = useHistory();
  const homeBox = useRef(null);
  const [submitBtnFlag, setSubmitBtnFlag] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const form_list = props.tradeInfo.form_list
  const countryList = getSessionStorage("cacheFilledData")?.countryList
  
  let bankOptions = [];
  form_list.some((m) => {
    if (
      props.tradeForm.country === m.country ||
      getSessionStorage("cacheFilledData")?.country === m.country
    ) {
      m.method.some((b) => {
        if (
          b.name === props.tradeForm.method ||
          b.name === getSessionStorage("cacheFilledData")?.method
        ) {
          bankOptions = b.bank_list;
          return true;
        }
      });
      return true;
    }
  });

  useEffect(() => {
    if (props.history.action === "PUSH") {
      homeBox.current.scrollTop = homeBox.current.scrollHeight;
    }
  }, []);
  const submitForm = () => {
    const selectedCountry = getSessionStorage("cacheFilledData")?.country;
    const method = getSessionStorage("cacheFilledData")?.method;
    const formValues = getSessionStorage("cacheFilledData")?.infomation;

    let requestData = {
      country: selectedCountry,
      method: method,
    };
    let arrivalCurrency = "";
    if (selectedCountry === "BRA") {
      if (method === "WALLET") {
        arrivalCurrency = "USD";
      } else {
        arrivalCurrency = "BRL";
      }
    } else if (selectedCountry === "MEX") {
      if (method === "WALLET") {
        arrivalCurrency = "USD";
      } else {
        arrivalCurrency = "MXN";
      }
    } else if(selectedCountry === "GLOBAL"){
      arrivalCurrency = "USD";
    }
    if (selectedCountry === "BRA" && method === "PIX") {
      let documentType = "";
      if (formValues.pixCpf_cnpj.replace(/[^0-9]/gi, "").length !== 14) {
        documentType = "cpf";
      } else {
        documentType = "cnpj";
      }
      let account = ""
      if(formValues.pixKeyType === "PHONE"){
        account = getPhoneAreaCode(selectedCountry,formValues.pixKey)
      }else{
        account = formValues.pixKey
      }
      requestData = {
        ...requestData,
        name: formValues.pixName,
        document_type: documentType,
        document_id: formValues.pixCpf_cnpj,
        account_type: formValues.pixKeyType?.split(",")[0],
        account: account,
        email: formValues.pixEmail,
        phone: formValues.pixCellphone,
        bank_code: "",
        branch: "",
        branch_digit: "",
        account_digit: "",
        channel: "",
        arrival_currency: arrivalCurrency,
        custom_code: propsTradeInfo.order_detail.custom_code,
        additional_remark: propsTradeInfo.order_detail.description,
      };
    } else if (selectedCountry === "BRA" && method === "BANKTRANSFER") {
      let documentType = "";
      if (
        formValues.banktranferCpf_cnpj.replace(/[^0-9]/gi, "").length !== 14
      ) {
        documentType = "cpf";
      } else {
        documentType = "cnpj";
      }
      requestData = {
        ...requestData,
        name: formValues.banktranferName,
        document_type: documentType,
        document_id: formValues.banktranferCpf_cnpj,
        account_type: formValues.banktranferAccountType?.split(",")[0],
        account: formValues.banktranferAccountNumber,
        email: formValues.banktranferEmail,
        phone: formValues.banktranferCellphone,
        bank_code: formatBank(formValues.banktranferBank,bankOptions),
        branch: formValues.banktranferBankBranch,
        branch_digit: formValues.banktranferBranchDigit,
        account_digit: formValues.banktranferAccountDigit,
        channel: "",
        arrival_currency: arrivalCurrency,
        custom_code: propsTradeInfo.order_detail.custom_code,
        additional_remark: propsTradeInfo.order_detail.description,
      };
    } else if ((selectedCountry === "BRA" || selectedCountry === "MEX") && method === "WALLET") {
      let documentType = "";
      if (formValues.walletCpf_cnpj.replace(/[^0-9]/gi, "").length !== 14) {
        documentType = "cpf";
      } else {
        documentType = "cnpj";
      }
      requestData = {
        ...requestData,
        name: formValues.walletName,
        document_type: documentType,
        document_id: formValues.walletCpf_cnpj,
        account_type: "EMAIL",
        account: formValues.walletAccount,
        email: formValues.walletEmail,
        phone: formValues.walletCellphone,
        bank_code: "",
        branch: "",
        branch_digit: "",
        account_digit: "",
        channel: "",
        arrival_currency: arrivalCurrency,
        custom_code: propsTradeInfo.order_detail.custom_code,
        additional_remark: propsTradeInfo.order_detail.description,
      };
    } else if (selectedCountry === "MEX" && method === "SPEI") {
      requestData = {
        ...requestData,
        name: formValues.speiName,
        document_type: formValues.speiDocumentType,
        document_id: formValues.speiDocumentId,
        account_type: formValues.speiAccountType?.split(",")[0],
        account: formValues.speiAccount,
        email: formValues.speiEmail,
        phone: formValues.speiCellphone,
        bank_code: formatBank(formValues.speiBank,bankOptions),
        branch: "",
        branch_digit: "",
        account_digit: "",
        channel: "",
        arrival_currency: arrivalCurrency,
        custom_code: propsTradeInfo.order_detail.custom_code,
        additional_remark: propsTradeInfo.order_detail.description,
      };
    } else if (selectedCountry === "GLOBAL" && method === "WALLET") {
      requestData = {
        ...requestData,
        country: formatCountry(formValues.paypalCountry, countryList),
        account_type: "EMAIL",
        account: formValues.paypalAccount,
        email: "",
        phone: "",
        bank_code: "",
        branch: "",
        branch_digit: "",
        account_digit: "",
        channel: "paypal",
        arrival_currency: arrivalCurrency,
        custom_code: propsTradeInfo.order_detail.custom_code,
        additional_remark: propsTradeInfo.order_detail.description,
      };
    }

    //console.log("requestData",requestData)
    
    if (submitBtnFlag) {
      return false;
    }
    setIsloading(true);
    setSubmitBtnFlag(true);
    $axios({
      url: "/api/internal/checkout/commit",
      method: "POST",
      data: requestData,
    })
      .then((res) => {
        if (res.code === 200) {
          notify("success", res.msg, 2000, () => {
            setSessionStorage("_t",res.data.token)
            history.push("/result?t=" + token);
            removeSessionStorage("cacheFilledData")
          });
        } else {
          setSubmitBtnFlag(false);
        }
      })
      .catch((err) => {
        setSubmitBtnFlag(false);
        setIsloading(false);
        notify("error", err.msg);
      });
  };
  return (
    <>
      <div
        className="app__loader"
        style={{ display: isLoading ? "block" : "none" }}
      >
        <div className="loader">
          <PuffLoader color="#01b88f" />
        </div>
      </div>
      <div className="confirm-info-wrap">
        <Header back={true} />
        <div className="home-box" ref={homeBox}>
          <PayerDetails />
          <ReceiverDetails
            country={getSessionStorage("cacheFilledData")?.country}
            method={getSessionStorage("cacheFilledData")?.method}
          />
        </div>

        <Footer cb={submitForm} timeFlag={true} />
      </div>
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
    tradeForm: state.tradeForm,
    tradeFormDetail: state.tradeFormDetail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmInfoPage);
