import * as Yup from "yup";
import { cpf, cnpj } from "cpf-cnpj-validator";
import { phoneAreaCodeMap } from "../../utils/config";

export const speiInitialValues = (propsInfamation) => {
  return {
    speiName: propsInfamation?.speiName || "",
    speiBank: propsInfamation?.speiBank || "",
    speiDocumentType: propsInfamation?.speiDocumentType || "",
    speiDocumentId: propsInfamation?.speiDocumentId || "",
    speiAccountType: propsInfamation?.speiAccountType || "",
    speiAccount: propsInfamation?.speiAccount || "",
    speiEmail: propsInfamation?.speiEmail || "",
    speiCellphone: propsInfamation?.speiCellphone || "",
  };
};

export const speiValidationSchema = (country, t) =>
  Yup.lazy((values) => {
    return Yup.object({
      speiName: (() => {
        const r = t("form.formValidate.invalidName");
        return Yup.string().required(r);
      })(),
      speiBank: (() => {
        const r = t("form.formValidate.selectBank");
        return Yup.string().required(r);
      })(),
      speiDocumentType: (() => {
        const r = t("form.formValidate.selectDocumentType");
        return Yup.string().required(r);
      })(),
      speiDocumentId: (() => {
        const r = t("form.formValidate.invalidId");
        return Yup.string().required(r);
      })(),
      speiAccountType: (() => {
        const r = t("form.formValidate.selectAccountType");
        return Yup.string().required(r);
      })(),
      speiAccount: (() => {
        const r = t("form.formValidate.invalidAccount");
        return Yup.string().required(r);
      })(),
      speiEmail: (() => {
        const r1 = t("form.formValidate.invalidEmailAddress");
        const r2 = t("form.formValidate.inputEmail");
        return Yup.string().email(r1).required(r2);
      })(),
      speiCellphone: (() => {
        const r1 = t("form.formValidate.inputCellphone");
        const r2 = t("form.formValidate.invalidCellphone");
        return Yup.string()
          .required(r1)
          .test("check cellphone", r2, (val) => {
            const reg = phoneAreaCodeMap[country].reg;
            return reg.test(val) ? true : false;
          });
      })(),
    });
  });

export const pixInitialValues = (propsInfamation) => {
  return {
    pixName: propsInfamation?.pixName || "",
    pixCpf_cnpj: propsInfamation?.pixCpf_cnpj || "",
    pixKeyType: propsInfamation?.pixKeyType || "",
    pixKey: propsInfamation?.pixKey || "",
    pixEmail: propsInfamation?.pixEmail || "",
    pixCellphone: propsInfamation?.pixCellphone || "",
  };
};

export const pixValidationSchema = (country, t) =>
  Yup.lazy((values) => {
    return Yup.object({
      pixName: (() => {
        const r = t("form.formValidate.invalidName");
        return Yup.string().required(r);
      })(),
      pixCpf_cnpj: (() => {
        const r1 = t("form.formValidate.inputCpfOrCnpj");
        const r2 = t("form.formValidate.invalidCpfOrCnpj");
        return Yup.string()
          .required(r1)
          .test("check cpf|cnpj", r2, (val) => {
            if (cpf.isValid(val) || cnpj.isValid(val)) {
              return true;
            } else {
              return false;
            }
          });
      })(),
      pixKeyType: (() => {
        const r = t("form.formValidate.selectKeyType");
        return Yup.string().required(r);
      })(),
      pixKey: (() => {
        const r1 = t("form.formValidate.fillPixKey");
        const r2 = t("form.formValidate.invalidPixKey");
        return Yup.string()
          .required(r1)
          .test("check pix key", r2, (val) => {
            if (!values.pixKeyType) {
              return true;
            } else {
              if (values.pixKeyType === "CPF") {
                return cpf.isValid(val) ? true : false;
              } else if (values.pixKeyType === "CNPJ") {
                return cnpj.isValid(val) ? true : false;
              } else if (values.pixKeyType === "EMAIL") {
                const emailReg = /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/;
                return emailReg.test(val) ? true : false;
              } else if (values.pixKeyType === "PHONE") {
                const phoneReg = /^(\+55|55)?\d{11}$/;
                return phoneReg.test(val) ? true : false;
              } else if (values.pixKeyType === "EVP") {
                const evpReg = /^([0-9a-zA-Z-]{32}|[0-9a-zA-Z-]{36})$/;
                return evpReg.test(val) ? true : false;
              } else {
                return true;
              }
            }
          });
      })(),
      pixEmail: (() => {
        const r1 = t("form.formValidate.invalidEmailAddress");
        const r2 = t("form.formValidate.inputEmail");
        return Yup.string().email(r1).required(r2);
      })(),
      pixCellphone: (() => {
        const r1 = t("form.formValidate.inputCellphone");
        const r2 = t("form.formValidate.invalidCellphone");
        return Yup.string()
          .required(r1)
          .test("check cellphone", r2, (val) => {
            const reg = phoneAreaCodeMap[country].reg;
            return reg.test(val) ? true : false;
          });
      })(),
    });
  });

export const banktranferInitialValues = (propsInfamation) => {
  return {
    banktranferName: propsInfamation?.banktranferName || "",
    banktranferCpf_cnpj: propsInfamation?.banktranferCpf_cnpj || "",
    banktranferBank: propsInfamation?.banktranferBank || "",
    banktranferAccountType: propsInfamation?.banktranferAccountType || "",
    banktranferAccountNumber: propsInfamation?.banktranferAccountNumber || "",
    banktranferAccountDigit: propsInfamation?.banktranferAccountDigit || "",
    banktranferBankBranch: propsInfamation?.banktranferBankBranch || "",
    banktranferBranchDigit: propsInfamation?.banktranferBranchDigit || "",
    banktranferEmail: propsInfamation?.banktranferEmail || "",
    banktranferCellphone: propsInfamation?.banktranferCellphone || "",
  };
};

export const banktranferValidationSchema = (country, t) =>
  Yup.lazy((values) => {
    return Yup.object({
      banktranferName: (() => {
        const r = t("form.formValidate.invalidName");
        return Yup.string().required(r);
      })(),
      banktranferCpf_cnpj: (() => {
        const r1 = t("form.formValidate.inputCpfOrCnpj");
        const r2 = t("form.formValidate.invalidCpfOrCnpj");
        return Yup.string()
          .required(r1)
          .test("check cpf|cnpj", r2, (val) => {
            if (cpf.isValid(val) || cnpj.isValid(val)) {
              return true;
            } else {
              return false;
            }
          });
      })(),
      banktranferBank: (() => {
        const r = t("form.formValidate.selectBank");
        return Yup.string().required(r);
      })(),
      banktranferAccountType: (() => {
        const r = t("form.formValidate.selectAccountType");
        return Yup.string().required(r);
      })(),
      banktranferAccountNumber: (() => {
        const r = t("form.formValidate.inputAccountNumber");
        return Yup.string().required(r);
      })(),
      banktranferAccountDigit: (() => {
        const r1 = t("form.formValidate.accountDigitLen12");
        const r2 = t("form.formValidate.inputAccountDigit");
        return Yup.string().max(2, r1).required(r2);
      })(),
      banktranferBankBranch: (() => {
        const r1 = t("form.formValidate.accountDigitLen15");
        const r2 = t("form.formValidate.inputBankBranch");
        return Yup.string().max(5, r1).required(r2);
      })(),
      banktranferEmail: (() => {
        const r1 = t("form.formValidate.invalidEmailAddress");
        const r2 = t("form.formValidate.inputEmail");
        return Yup.string().email(r1).required(r2);
      })(),
      banktranferCellphone: (() => {
        const r1 = t("form.formValidate.inputCellphone");
        const r2 = t("form.formValidate.invalidCellphone");
        return Yup.string()
          .required(r1)
          .test("check cellphone", r2, (val) => {
            const reg = phoneAreaCodeMap[country].reg;
            return reg.test(val) ? true : false;
          });
      })(),
    });
  });

export const paypalInitialValues = (propsInfamation) => {
  return {
    paypalCountry: propsInfamation?.paypalCountry || "",
    paypalAccount: propsInfamation?.paypalAccount || "",
    paypalConfirmAccount: propsInfamation?.paypalConfirmAccount || "",
  };
};

export const paypalValidationSchema = (country, t) =>
  Yup.lazy((values) => {
    return Yup.object({
      paypalCountry: (() => {
        const r = t("form.formValidate.selectCountry");
        return Yup.string().required(r);
      })(),
      paypalAccount: (() => {
        const r1 = t("form.formValidate.invalidEmailAddress");
        const r2 = t("form.formValidate.invalidAccount");
        return Yup.string().email(r1).required(r2);
      })(),
      paypalConfirmAccount: (() => {
        const r1 = t("form.formValidate.invalidEmailAddress");
        const r2 = t("form.formValidate.invalidAccount");
        const r3 = t("form.formValidate.inconsistent");
        return Yup.string()
          .email(r1)
          .required(r2)
          .test("check same account", r3, (val) => {
            return values.paypalAccount === val ? true : false;
          });
      })(),
    });
  });

export const walletInitialValues = (propsInfamation) => {
  return {
    walletName: propsInfamation?.walletName || "",
    walletCpf_cnpj: propsInfamation?.walletCpf_cnpj || "",
    walletAccount: propsInfamation?.walletAccount || "",
    walletCellphone: propsInfamation?.walletCellphone || "",
    walletEmail: propsInfamation?.walletEmail || "",
  };
};

export const walletValidationSchema = (country, t) =>
  Yup.lazy((values) => {
    return Yup.object({
      walletName: (() => {
        const r = t("form.formValidate.invalidName");
        return Yup.string().required(r);
      })(),
      walletCpf_cnpj: (() => {
        const r1 = t("form.formValidate.inputCpfOrCnpj");
        const r2 = t("form.formValidate.invalidCpfOrCnpj");
        return Yup.string()
          .required(r1)
          .test("check cpf|cnpj", r2, (val) => {
            if (cpf.isValid(val) || cnpj.isValid(val)) {
              return true;
            } else {
              return false;
            }
          });
      })(),
      walletAccount: (() => {
        const r1 = t("form.formValidate.invalidEmailAddress");
        const r2 = t("form.formValidate.invalidAccount");
        return Yup.string().email(r1).required(r2);
      })(),
      walletEmail: (() => {
        const r1 = t("form.formValidate.invalidEmailAddress");
        const r2 = t("form.formValidate.inputEmail");
        return Yup.string().email(r1).required(r2);
      })(),
      walletCellphone: (() => {
        const r1 = t("form.formValidate.inputCellphone");
        const r2 = t("form.formValidate.invalidCellphone");
        return Yup.string()
          .required(r1)
          .test("check cellphone", r2, (val) => {
            const reg = phoneAreaCodeMap[country].reg;
            return reg.test(val) ? true : false;
          });
      })(),
    });
  });

export const walletLabelMap = (t) => {
  return {
    name: t("form.formValidate.name"),
    cpf_cnpj: "CPF / CNPJ",
    account: t("form.formValidate.account"),
    email: t("form.formValidate.email"),
    cellphone: t("form.formValidate.cellphone"),
  };
};

export const pixLabelMap = (t) => {
  return {
    name: t("form.formValidate.name"),
    cpf_cnpj: "CPF / CNPJ",
    key_type: t("form.formValidate.keyType"),
    pix_key: t("form.formValidate.pixType"),
    email: t("form.formValidate.email"),
    cellphone: t("form.formValidate.cellphone"),
  };
};

export const banktranferLabelMap = (t) => {
  return {
    name: t("form.formValidate.name"),
    cpf_cnpj: "CPF / CNPJ",
    bank: t("form.formValidate.bank"),
    account_type: t("form.formValidate.accountType"),
    account_number: t("form.formValidate.accountNumber"),
    account_digit: t("form.formValidate.accountDigit"),
    bank_branch: t("form.formValidate.bankBranch"),
    branch_digit: t("form.formValidate.bankDigit"),
    email: t("form.formValidate.email"),
    cellphone: t("form.formValidate.cellphone"),
  };
};

export const speiLabelMap = (t) => {
  return {
    name: t("form.formValidate.name"),
    bank: t("form.formValidate.bank"),
    document_type: t("form.formValidate.documentType"),
    document_id: t("form.formValidate.documentId"),
    account_type: t("form.formValidate.accountType"),
    account: t("form.formValidate.account"),
    email: t("form.formValidate.email"),
    cellphone: t("form.formValidate.cellphone"),
  };
};
export const lngTransform = (key) => {
  if (key?.indexOf(",") >= 0) {
    key = key?.split(",")[1];
  }
  if (key === "CHECKING" || key === "VERIFICANDO") {
    return "CHECKING," + key;
  } else if (key === "SAVINGS" || key === "AHORROS" || key === "POUPANÇA") {
    return "SAVINGS," + key;
  } else if (
    key === "CPF" ||
    key === "CNPJ" ||
    key === "EVP" ||
    key === "CLABE"
  ) {
    return key + "," + key;
  } else if (key === "PHONE" || key === "TELÉFONO" || key === "TELEFONE") {
    return "PHONE," + key;
  } else if (key === "EMAIL" || key === "O EMAIL") {
    return "EMAIL," + key;
  } else if (key === "DEBIT" || key === "DÉBITO") {
    return "DEBIT," + key;
  }
};

export const globalPaypalLabelMap = (t) => {
  return {
    country: t("form.formValidate.paypalCountry"),
    account: t("form.formValidate.paypalAccount"),
    confirmAccount: t("form.formValidate.rePaypalAccount"),
  };
};
