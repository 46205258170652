import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { privacyEn, privacyEs, privcayPt } from "./privacy";
import { formValidateEn, formValidateEs, formValidatePt } from "./formValidate";
import { setSessionStorage, getSessionStorage } from "../utils/config";

if (!getSessionStorage("lng")) {
  setSessionStorage("lng", "pt");
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: getSessionStorage("lng") || "pt",
    fallbackLng: getSessionStorage("lng") || "pt",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          ...privacyEn,
          ...formValidateEn,
          privacy_policy_error:
            "You must read and accept the Terms of Use and Privacy Policy to complete your purchase.",
          help_chat: "Help chat",
          instructions: "Instructions",
          faq: "FAQ",
          videos: "Videos",
          pictures: "Pictures",
          text: "Text",
          home_step1: "Choose final country",
          home_step2: "Choose payment method",
          home_step3_t1: "Fill in the details",
          home_step3_t2: "RECEIVER's INFORMATION",
          time_remain: "Time remaining",
          payer: "PAYER",
          general: "GENERAL",
          transaction_id: "Transaction ID",
          description: "Description",
          currency: "CURRENCY",
          from: "From",
          exchange_rate: "Exchange rate",
          to: "To",
          taxes: "Taxes",
          bank_fee: "Bank fee",
          total: "TOTAL",
          confirm: "Confirm",
          back_txt: "Go back to merchant’s page",
          pricacy1: "I have read and agree to the terms of use and",
          pricacy2: "Privacy Policy",
          pricacy3: "of Pagsmile",
          transation_processing: "Processing transaction",
          transaction_success: "Transaction Successful",
          transaction_cancelled: "Transaction Cancelled",
          transaction_failed: "Transaction Failed",
          transaction_failed_label: "",
          result_tip1: "Thank you for paying with Pagsmile",
          result_tip2: "To know more about us, visit",
          try_again: "Try again",
          help: "Help",
          paid_tip1: "Make another payment",
          paid_tip2: "Initial Page",
          cancelled_tip1:
            "The time limit for your payment has ended, please return back to seller page to make a new order",
          receiver_name: "Name",
          receiver_cpf_cnpj: "CPF/CNPJ",
          receiver_key_type: "Key Type",
          receiver_pix_key: "Pix Key",
          receiver_email: "E-mail",
          receiver_cellphone: "Cellphone",
          receiver_document_type: "Document type",
          receiver_document_id: "Id",
          receiver_document_bank: "Bank",
          receiver_account_type: "Account type",
          receiver_account: "Account",
          receiver_bank: "Bank",
          receiver_bank_branch: "Bank Branch",
          receiver_account_digit: "Account Digit",
          receiver_branch_digit: "Branch Digit",
          receiver_title1: "FINAL COUNTRY",
          receiver_title2: "PAYMENT METHOD",
          receiver_title3: "RECEIVER",
          paypal_country: "Paypal country",
          paypal_account: "Paypal account",
        },
      },
      es: {
        translation: {
          ...formValidateEn,
          ...formValidateEs,
          privacy_policy_error:
            "Debe leer y aceptar los Términos de uso y la Política de privacidad para completar su compra.",
          help_chat: "Chat de ayuda",
          instructions: "Instrucciones",
          faq: "Preguntas más frecuentes",
          videos: "Videos",
          pictures: "Fotos",
          text: "Texto",
          home_step1: "Selecciona el país final",
          home_step2: "Escoge el método de pago",
          home_step3_t1: "Complete los detalles",
          home_step3_t2: "INFORMACIÓN DEL RECEPTOR",
          time_remain: "Tiempo restante",
          payer: "PAGADOR",
          general: "GENERAL",
          transaction_id: "ID de transacción",
          description: "Descripción",
          currency: "MONEDA",
          from: "De",
          exchange_rate: "Tipo de cambio",
          to: "Para",
          taxes: "Impuestos",
          bank_fee: "Banco gratis",
          total: "TOTAL",
          confirm: "Confirmar",
          back_txt: "Regrese a la página del comerciante",
          pricacy1: "He leído y acepto los términos de uso y la",
          pricacy2: "Política de privacidad",
          pricacy3: "de Pagsmile",
          transation_processing: "Transacción en proceso",
          transaction_success: "Transacción exitosa",
          transaction_cancelled: "Transacción cancelada",
          transaction_failed: "Transacción fallida",
          transaction_failed_label: "",
          result_tip1: "Gracias por pagar con Pagsmile",
          result_tip2: "Para saber más sobre nosotros, click aquí",
          try_again: "Intentar de nuevo",
          help: "Ayuda",
          paid_tip1: "Hacer otro pago",
          paid_tip2: "Página inicial",
          cancelled_tip1:
            "El tiempo límite de pago ha finalizado, vuelva a la página del vendedor para realizar un nuevo pedido.",
          receiver_name: "Nombre",
          receiver_cpf_cnpj: "Cpf / Cnpj",
          receiver_key_type: "Tipo de clave",
          receiver_pix_key: "Pix Key",
          receiver_email: "Correo electrónico",
          receiver_cellphone: "Teléfono móvil",
          receiver_document_type: "Tipo de Documento",
          receiver_document_id: "Identificación",
          receiver_document_bank: "Banco",
          receiver_account_type: "Tipo de cuenta",
          receiver_account: "Cuenta",
          receiver_bank: "Banco",
          receiver_bank_branch: "Sucursal bancaria",
          receiver_account_digit: "Dígito de la cuenta",
          receiver_branch_digit: "Dígito de la sucursal",
          receiver_title1: "PAÍS FINAL",
          receiver_title2: "FORMA DE PAGAMENTO",
          receiver_title3: "RECEPTOR",
          paypal_country: "País da conta Paypal",
          paypal_account: "Conta Paypal",
        },
      },
      pt: {
        translation: {
          ...formValidateEs,
          ...formValidatePt,
          privacy_policy_error:
            "Você deve ler e aceitar os Termos de Uso e a Política de Privacidade para concluir sua compra.",
          help_chat: "Chat de ajuda",
          instructions: "Instruções",
          faq: "Perguntas frequentes",
          videos: "Videos",
          pictures: "Fotos",
          text: "Texto",
          home_step1: "Escolha o país final",
          home_step2: "Escolha o método de pagamento",
          home_step3_t1: "Preencha os detalhes",
          home_step3_t2: "INFORMAÇÕES DO RECEBEDOR",
          time_remain: "Tempo restante",
          payer: "PAGADOR",
          general: "EM GERAL",
          transaction_id: "ID de transação",
          description: "Descrição",
          currency: "MOEDA",
          from: "A partir de",
          exchange_rate: "Taxa de câmbio",
          to: "Para",
          taxes: "Impostos",
          bank_fee: "Banco grátis",
          total: "TOTAL",
          confirm: "Confirmar",
          back_txt: "Volte para a página do comerciante",
          pricacy1: "Eu li e concordo com os termos de uso e",
          pricacy2: "Política de Privacidade",
          pricacy3: "da Pagsmile",
          transation_processing: "Processando Transação",
          transaction_success: "Transação bem-sucedida",
          transaction_cancelled: "Transação cancelada",
          transaction_failed: "Falha na transação",
          transaction_failed_label: "",
          result_tip1: "Obrigado por pagar com Pagsmile",
          result_tip2: "Para saber mais sobre nós, visite",
          try_again: "Tente novamente",
          help: "Ajuda",
          paid_tip1: "Faça outro pagamento",
          paid_tip2: "Página inicial",
          cancelled_tip1:
            "O prazo para o seu pagamento terminou, volte à página do vendedor para fazer um novo pedido.",
          receiver_name: "Nome",
          receiver_cpf_cnpj: "Cpf / Cnpj",
          receiver_key_type: "Tipo de chave",
          receiver_pix_key: "Chave Pix",
          receiver_email: "Email",
          receiver_cellphone: "Celular",
          receiver_document_type: "Tipo de documento",
          receiver_document_id: "Identidade",
          receiver_document_bank: "Banco",
          receiver_account_type: "Tipo de conta",
          receiver_account: "Conta",
          receiver_bank: "Banco",
          receiver_bank_branch: "Agência bancária",
          receiver_account_digit: "Número da conta",
          receiver_branch_digit: "Dígito da filial",
          receiver_title1: "PAÍS FINAL",
          receiver_title2: "FORMA DE PAGO",
          receiver_title3: "RECEPTOR",
          paypal_country: "País de la cuenta Paypal",
          paypal_account: "Cuenta de Paypal",
        },
      },
    },
  });

export default i18n;
