import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { Formik, Form } from "formik";
import Checkbox from "@material-ui/core/Checkbox";
import { setTradeForm, setShowPolicy } from "../../redux/actions";
import PrivacyPolicy from "../homePage/PrivacyPolicy";

import FormInput from "../../package/form/FormInput";
import FormSelect from "../../package/form/FormSelect";
import * as config from "./formConfig";
import {
  getQueryVariable,
  setSessionStorage,
  getSessionStorage,
  getPhoneAreaCode,
} from "../../utils/config";

import $axios from "../../utils/http";

import "./ReceiverInfoPage.scss";

function ReceiverInfoPage(props) {
  console.log("ReceiverInofPage-props-----2222222222", props);
  const { t } = useTranslation();
  const method =
    props.tradeForm?.method || getSessionStorage("cacheFilledData")?.method;
  const country =
    props.tradeForm?.country || getSessionStorage("cacheFilledData")?.country;
  const propsInfamation = props.tradeForm?.infomation;
  const token = getQueryVariable("t");
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(true);
  const [paypalCountryOptions, setPaypalCountryOptions] = useState([]);

  const handleChangePrivacy = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleShowPolicy = () => {
    props.setShowPolicy(true);
  };

  if (!method) {
    history.push("/?t=" + token);
  }

  // 获取global paypal的国家列表
  useEffect(() => {
    $axios({
      url: "/api/internal/checkout/paypal/countries",
      method: "POST",
    }).then((res) => {
      if (res.code === 200) {
        setPaypalCountryOptions(res.data);
        const cacheFilledData = getSessionStorage("cacheFilledData");
        const _data = {
          ...cacheFilledData,
          countryList: res.data,
        };
        setSessionStorage("cacheFilledData", JSON.stringify(_data));
      }
    });
  }, []);

  const accountTypeOptions = [
    { name: t("form.formSections.checking"), value: "CHECKING" },
    { name: t("form.formSections.savings"), value: "SAVINGS" },
  ];
  const pixKeyTypeOptions = [
    { name: "CPF", value: "CPF" },
    { name: "CNPJ", value: "CNPJ" },
    { name: t("form.formSections.phone"), value: "PHONE" },
    { name: t("form.formSections.email"), value: "EMAIL" },
    { name: "EVP", value: "EVP" },
  ];
  const speiAccountTypeOptions = [
    { name: t("home.formSections.debit"), value: "DEBIT" },
    { name: t("home.formSections.phone"), value: "PHONE" },
    { name: "CLABE", value: "CLABE" },
  ];

  const speiDocumentTypeOptions = [
    { name: "RFC", value: "RFC" },
    { name: "CURP", value: "CURP" },
  ];
  const closePolicy = () => {
    props.setShowPolicy(false);
  };

  let bankOptions = [];
  props.tradeInfo.form_list.some((m) => {
    if (
      props.tradeForm.country === m.country ||
      getSessionStorage("cacheFilledData")?.country === m.country
    ) {
      m.method.some((b) => {
        if (
          b.name === props.tradeForm.method ||
          b.name === getSessionStorage("cacheFilledData")?.method
        ) {
          bankOptions = b.bank_list;
          return true;
        }
      });
      return true;
    }
  });

  return (
    <>
      {props.showPolicy}
      {props.showPolicy && <PrivacyPolicy back={closePolicy} />}
      <div className="receiver-info-wrap">
        <Header back={true} />
        <div
          className="receiver-info-box"
          style={{ bottom: !props?.showToggle ? "12rem" : "27rem" }}
        >
          <p className="title">
            {t("home_step3_t2")} {country}-{method}
          </p>
          {country === "BRA" && method === "BANKTRANSFER" && (
            <Formik
              initialValues={config.banktranferInitialValues(propsInfamation)}
              validationSchema={config.banktranferValidationSchema(country, t)}
              onSubmit={async (values, { setSubmitting }) => {
                if (!isChecked) {
                  return false;
                }
                values = {
                  ...values,
                  banktranferAccountType: config.lngTransform(
                    values.banktranferAccountType
                  ),
                };
                props.setTradeForm({ ...props.tradeForm, infomation: values });
                setSessionStorage("cacheFilledData", {
                  ...getSessionStorage("cacheFilledData"),
                  infomation: values,
                });
                history.push("/?t=" + token);
              }}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit} autoComplete="off">
                  <div className="form-item-box">
                    <FormInput
                      label={config.banktranferLabelMap(t).name}
                      name="banktranferName"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.banktranferLabelMap(t).cpf_cnpj}
                      name="banktranferCpf_cnpj"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormSelect
                      label={config.banktranferLabelMap(t).bank}
                      name="banktranferBank"
                      options={bankOptions}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                      value={props.values.banktranferBank}
                      error={props.errors.banktranferBank}
                      touched={props.touched.banktranferBank}
                    />
                  </div>
                  <div className="form-item-box">
                    <FormSelect
                      label={config.banktranferLabelMap(t).account_type}
                      name="banktranferAccountType"
                      options={accountTypeOptions}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                      value={
                        props.values.banktranferAccountType.indexOf(",") >= 0
                          ? props.values.banktranferAccountType.split(",")[1]
                          : props.values.banktranferAccountType
                      }
                      error={props.errors.banktranferAccountType}
                      touched={props.touched.banktranferAccountType}
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.banktranferLabelMap(t).account_number}
                      name="banktranferAccountNumber"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.banktranferLabelMap(t).account_digit}
                      name="banktranferAccountDigit"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.banktranferLabelMap(t).bank_branch}
                      name="banktranferBankBranch"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.banktranferLabelMap(t).branch_digit}
                      name="banktranferBranchDigit"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.banktranferLabelMap(t).email}
                      name="banktranferEmail"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.banktranferLabelMap(t).cellphone}
                      name="banktranferCellphone"
                    />
                  </div>
                  <button type="submit" className="submit-btn">
                    submit
                  </button>
                </Form>
              )}
            </Formik>
          )}
          {country === "BRA" && method === "PIX" && (
            <Formik
              initialValues={config.pixInitialValues(propsInfamation)}
              validationSchema={config.pixValidationSchema(country, t)}
              onSubmit={async (values, { setSubmitting }) => {
                if (!isChecked) {
                  return false;
                }
                if (
                  values.pixKeyType === "PHONE" ||
                  values.pixKeyType === "TELÉFONO" ||
                  values.pixKeyType === "TELEFONE"
                ) {
                  const phone = getPhoneAreaCode(country, values.pixKey);
                  values = { ...values, pixKey: phone };
                }
                values = {
                  ...values,
                  pixKeyType: config.lngTransform(values.pixKeyType),
                };
                props.setTradeForm({ ...props.tradeForm, infomation: values });
                setSessionStorage("cacheFilledData", {
                  ...getSessionStorage("cacheFilledData"),
                  infomation: values,
                });
                history.push("/?t=" + token);
              }}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit} autoComplete="off">
                  <div className="form-item-box">
                    <FormInput
                      label={config.pixLabelMap(t).name}
                      name="pixName"
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.pixLabelMap(t).cpf_cnpj}
                      name="pixCpf_cnpj"
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormSelect
                      label={config.pixLabelMap(t).key_type}
                      name="pixKeyType"
                      options={pixKeyTypeOptions}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                      value={
                        props.values.pixKeyType.indexOf(",") >= 0
                          ? props.values.pixKeyType.split(",")[1]
                          : props.values.pixKeyType
                      }
                      error={props.errors.pixKeyType}
                      touched={props.touched.pixKeyType}
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.pixLabelMap(t).pix_key}
                      name="pixKey"
                      id={props.values.pixKeyType}
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.pixLabelMap(t).email}
                      name="pixEmail"
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.pixLabelMap(t).cellphone}
                      name="pixCellphone"
                      type="text"
                    />
                  </div>
                  <button type="submit" className="submit-btn">
                    submit
                  </button>
                </Form>
              )}
            </Formik>
          )}
          {(country === "BRA" || country === "MEX") && method === "WALLET" && (
            <Formik
              initialValues={config.walletInitialValues(propsInfamation)}
              validationSchema={config.walletValidationSchema(country, t)}
              onSubmit={async (values, { setSubmitting }) => {
                if (!isChecked) {
                  return false;
                }
                props.setTradeForm({ ...props.tradeForm, infomation: values });
                setSessionStorage("cacheFilledData", {
                  ...getSessionStorage("cacheFilledData"),
                  infomation: values,
                });
                history.push("/?t=" + token);
              }}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit} autoComplete="off">
                  <div className="form-item-box">
                    <FormInput
                      label={config.walletLabelMap(t).name}
                      name="walletName"
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.walletLabelMap(t).cpf_cnpj}
                      name="walletCpf_cnpj"
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.walletLabelMap(t).account}
                      name="walletAccount"
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.walletLabelMap(t).email}
                      name="walletEmail"
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.walletLabelMap(t).cellphone}
                      name="walletCellphone"
                      type="text"
                    />
                  </div>
                  <button type="submit" className="submit-btn">
                    submit
                  </button>
                </Form>
              )}
            </Formik>
          )}
          {country === "MEX" && method === "SPEI" && (
            <Formik
              initialValues={config.speiInitialValues(propsInfamation)}
              validationSchema={config.speiValidationSchema(country, t)}
              onSubmit={async (values, { setSubmitting }) => {
                console.log("SPEI", values);
                if (!isChecked) {
                  return false;
                }
                values = {
                  ...values,
                  speiAccountType: config.lngTransform(values.speiAccountType),
                };
                props.setTradeForm({ ...props.tradeForm, infomation: values });
                setSessionStorage("cacheFilledData", {
                  ...getSessionStorage("cacheFilledData"),
                  infomation: values,
                });
                history.push("/?t=" + token);
              }}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit} autoComplete="off">
                  <div className="form-item-box">
                    <FormInput
                      label={config.speiLabelMap(t).name}
                      name="speiName"
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormSelect
                      label={config.speiLabelMap(t).bank}
                      name="speiBank"
                      options={bankOptions}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                      value={props.values.speiBank}
                      error={props.errors.speiBank}
                      touched={props.touched.speiBank}
                    />
                  </div>
                  <div className="form-item-box">
                    <FormSelect
                      label={config.speiLabelMap(t).document_type}
                      name="speiDocumentType"
                      options={speiDocumentTypeOptions}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                      value={props.values.speiDocumentType}
                      error={props.errors.speiDocumentType}
                      touched={props.touched.speiDocumentType}
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.speiLabelMap(t).document_id}
                      name="speiDocumentId"
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormSelect
                      label={config.speiLabelMap(t).account_type}
                      name="speiAccountType"
                      options={speiAccountTypeOptions}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                      value={
                        props.values.speiAccountType.indexOf(",") >= 0
                          ? props.values.speiAccountType.split(",")[1]
                          : props.values.speiAccountType
                      }
                      error={props.errors.speiAccountType}
                      touched={props.touched.speiAccountType}
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.speiLabelMap(t).account}
                      name="speiAccount"
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.speiLabelMap(t).email}
                      name="speiEmail"
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.speiLabelMap(t).cellphone}
                      name="speiCellphone"
                    />
                  </div>
                  <button type="submit" className="submit-btn">
                    submit
                  </button>
                </Form>
              )}
            </Formik>
          )}
          {country === "GLOBAL" && method === "WALLET" && (
            <Formik
              initialValues={config.paypalInitialValues(propsInfamation)}
              validationSchema={config.paypalValidationSchema(country, t)}
              onSubmit={async (values, { setSubmitting }) => {
                // console.log("global",values)
                if (!isChecked) {
                  return false;
                }
                props.setTradeForm({ ...props.tradeForm, infomation: values });
                setSessionStorage("cacheFilledData", {
                  ...getSessionStorage("cacheFilledData"),
                  infomation: values,
                });
                history.push("/?t=" + token);
              }}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit} autoComplete="off">
                  <div className="form-item-box">
                    <FormSelect
                      label={config.globalPaypalLabelMap(t).country}
                      name="paypalCountry"
                      options={paypalCountryOptions}
                      value={props.values.paypalCountry}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                      error={props.errors.paypalCountry}
                      touched={props.touched.paypalCountry}
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.globalPaypalLabelMap(t).account}
                      name="paypalAccount"
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.globalPaypalLabelMap(t).confirmAccount}
                      name="paypalConfirmAccount"
                      type="text"
                    />
                  </div>
                  <button type="submit" className="submit-btn">
                    submit
                  </button>
                </Form>
              )}
            </Formik>
          )}
          <div className="checkbox-wrap">
            <Checkbox
              size="small"
              checked={isChecked}
              onChange={handleChangePrivacy}
            />
            <span className="privacy-box">
              {t("pricacy1")}{" "}
              <span className="privacy" onClick={handleShowPolicy}>
                {t("pricacy2")}
              </span>{" "}
              {t("pricacy3")}
            </span>
          </div>
          {!isChecked && (
            <p className="privacy-policy-error">{t("privacy_policy_error")}</p>
          )}
        </div>
        <Footer currencyFlag={true} />
      </div>
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
    tradeForm: state.tradeForm,
    showPolicy: state.showPolicy,
    showToggle: state.showToggle,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setTradeForm: (item) => dispatch(setTradeForm(item)),
    setShowPolicy: (item) => dispatch(setShowPolicy(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReceiverInfoPage);
