import { SET_TRADE_INFO, SET_TRADE_FORM, SET_TRADE_FORM_DETAIL, SET_SHOW_POLICY, SET_SHOW_TOGGLE  } from "./types";

export const setTradeInfo = (item) => {
  return {
    type: SET_TRADE_INFO,
    item: item,
  };
};

export const setTradeForm = (item) => {
  return {
    type: SET_TRADE_FORM,
    item: item,
  };
};

export const setShowToggle = (item) => {
  return {
    type: SET_SHOW_TOGGLE,
    item: item,
  };
};

export const setTradeFormDetail = (item) => {
  return {
    type: SET_TRADE_FORM_DETAIL,
    item: item,
  };
};

export const setShowPolicy = (item) => {
  return {
    type: SET_SHOW_POLICY,
    item: item,
  };
};


